import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';

import TopBarTags from './TopBarTags';
import Version from './Version';
import StaticSearchContext from './TopBarTags/context';
import useStaticSearch from '../hooks/useStaticSearch';

const Layout = (props) => {
  return (
    <AppWithoutAuthentication {...props} />
  );
}

const AppWithoutAuthentication = ({ children, ...props }) => {
  const search = useStaticSearch({ items: props.items });
  return (
  <Fragment>
    <Version />
      <StaticSearchContext.Provider value={search}>
        <TopBarTags {...props} search={search}/>
        <Container maxWidth="lg">
          {children}
        </Container>
      </StaticSearchContext.Provider>
    <Version />
  </Fragment>
)};

export default Layout;
