import React from 'react';

const StaticSearchContext = React.createContext();

export const withSearch = Component => props => {
  return (
    <StaticSearchContext.Consumer>
      {search => <Component {...props} search={search} />}
    </StaticSearchContext.Consumer>
  );  
}

export default StaticSearchContext;
