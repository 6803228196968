import { useEffect, useState, useRef } from 'react';
import { globalHistory } from "@reach/router";

const useStaticSearch = (props) => {
  const searchEnabled = Boolean(props.items)
  const [searchIndex, setSearch] = useState();
  const [fuse, setFuse] = useState();
  const [searchResult, setSearchResult] = useState();
  const [needsSearch, setNeedsSearch] = useState(true)
  const preSearch = useRef()
  const preSearchDone = useRef(false)

  const urlHas = (key) => {
    const urlParams = new URLSearchParams(globalHistory.location.search);
    // console.log('urlHas', key, urlParams.get(key))
    return urlParams.get(key) || '';
  }
  
  useEffect(() => {
    const searchOptions = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 50,
      includeScore: true,
      minMatchCharLength: 3,
      keys: [{
        name: 'title',
        weight: 0.8,
      }, {
        name: 'names',
        weight: 0.2,
      }]
    }
  
    const fetchHandler = async () => {
      // console.log('initialize search')
      const fuse = await import('fuse.js');
      const options = {
        ...searchOptions,
        keys: ['title', 'names']
      }
      const theFuse = new fuse.default(props.items, options)
      setFuse(theFuse);
      preSearch.current = urlHas('search');
      // console.log('preSearch.current', preSearch.current)

      setSearch(props.items);
      // console.log('filter', preSearch.current, needsSearch.current, preSearch.current.length > 0 )
      if (preSearch.current !== undefined && preSearch.current.length > 0) {
        // console.log('doing presearch')
        setNeedsSearch(true)
        preSearchDone.current = true;
        const result = theFuse.search(preSearch.current).map((e) => e.item);
        setSearchResult(result);
        // console.log('preSearchDone.current', preSearchDone.current)
      } else {
        // console.log('presearch not required')
        setNeedsSearch(false);
        preSearchDone.current = true;
      }

    };
    if (!searchIndex && searchEnabled) {
      fetchHandler();
    }
  }, [searchIndex, searchEnabled, props.items]); 

  return {
    searchEnabled,
    searchIndex,
    fuse,
    searchResult,
    setSearchResult,
    needsSearch: needsSearch,
    setNeedsSearch,
    preSearch: preSearch.current,
    preSearchDone: preSearchDone.current,
  }
}

export default useStaticSearch;




