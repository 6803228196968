import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { AppBar, Toolbar, Slide } from '@material-ui/core';
import { globalHistory } from "@reach/router";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';

import LogoIcon from '../../assets/logo/round';
// import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ClientOnly from '../ClientOnly';
import MenuBar from '../MenuBar';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
    
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  root: {
    flexGrow: 1,
  },
  active: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:-webkit-any-link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',

    },
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }  
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:-webkit-any-link': {
      color: theme.palette.text.primary,
      textDecoration: 'none',

    },
    '&:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }  
  },
  icon: {
    marginRight: theme.spacing(1),
  }
}));

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Search = React.lazy(() => import('../Search'));

export default function TopBar(props) {
  const classes = useStyles();

  const extraTitle = (props.title) ? `: ${props.title}` : ''
  const title = `Juegos de Mesa${extraTitle}`
  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar color="primary">
          <Toolbar>
            <MenuBar/>
            <LogoIcon className={classes.icon}/>
            <Hidden only={['xs']}>
              <Typography variant="h6" component="h1" className={classes.title}>
                {title}
              </Typography>
            </Hidden>
            <ClientOnly withLoading={false}>
              <React.Suspense fallback={<Skeleton variant="rect"><InputBase /></Skeleton>}>
                <Search search={props.search} />
              </React.Suspense>
            </ClientOnly>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}